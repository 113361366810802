import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// import useHover from '@assets/scripts/hooks/useHover';
// components
import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';
// import FormNewsletter from '@components/molecules/FormNewsletter';

const Cta = ({
	className,
	text,
	cta,
	url,
	subscribe,
	currentPage,
	...otherProps
}) => {
	const baseClass = 'cta';

	const rootClass = cx(baseClass, className);

	// interaction
	// const [hoverOn, hoverOff] = useHover();

	return (
		<div className={rootClass} {...otherProps} data-animation-page>
			<div className="cta__inner">
				<p className="cta__text" data-animation-page>
					{text}
				</p>
				{!!subscribe ? (
					<>
						{/* <FormNewsletter cta={cta} currentPage={currentPage} /> */}
					</>
				) : cta && url ? (
					<ButtonPrimary href={url} text={cta} data-target />
				) : null}
			</div>
		</div>
	);
};

Cta.defaultProps = {
	subscribe: false,
};

Cta.propTypes = {
	className: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	cta: PropTypes.string,
	url: PropTypes.string,
	subscribe: PropTypes.bool,
	currentPage: PropTypes.string,
};

export default Cta;
