import '../card.scss';
import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from '@components/atoms/LinkPage';
import useHover from '@assets/scripts/hooks/useHover';
// assets
import imgPlaceholder from '@assets/images/team/teamPlaceholder.jpg';
// components
import Image from '@components/atoms/Image';

const CardTeam = ({
	className,
	modifiers,
	name,
	teamRole,
	statistic,
	image,
	link,
	...otherProps
}) => {
	const baseClass = 'card-team';

	const rootClass = cx(baseClass, className, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	// interaction
	const [hoverOn, hoverOff] = useHover();

	return (
		<div data-target className={rootClass} {...otherProps}>
			<figure className="card-team__thumbnail" aria-label={name}>
				{image && (
					<Image
						className="card-team__img"
						{...image}
						objectFit="contain"
					/>
				)}
				<span className="card-team__shadow">
					{modifiers && modifiers.includes('statistic') && (
						<div className="card-team__details">
							<p className="card-team__statistic">{statistic}</p>
							<Link
								to={link}
								data-target
								onMouseEnter={() => hoverOn()}
								onMouseLeave={() => hoverOff()}
							>
								<p className="card-team__name">{name}</p>
							</Link>
						</div>
					)}
				</span>
			</figure>
			{(!modifiers || !modifiers.includes('statistic')) && (
				<div className="card-team__details">
					{/* <Link
						to={link}
						data-target
						onMouseEnter={() => hoverOn()}
						onMouseLeave={() => hoverOff()}
					> */}
					<p className="card-team__name">{name}</p>
					{/* </Link> */}
					<p className="card-team__role">{teamRole}</p>
				</div>
			)}
		</div>
	);
};

CardTeam.defaultProps = {
	image: imgPlaceholder,
};

CardTeam.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	teamRole: PropTypes.string,
	statistic: PropTypes.string,
	image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	link: PropTypes.string,
};

export default CardTeam;
