import '../item.scss';
import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';

const ItemArticle = ({ modifiers, className, title, link, ...otherProps }) => {
	const baseClass = 'item-article';
	const defaultClass = 'item';

	const rootClass = cx(baseClass, className, defaultClass, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	return (
		<div className={rootClass} data-item {...otherProps}>
			<p
				dangerouslySetInnerHTML={{
					__html: title,
				}}
			></p>
			<ButtonSecondary href={link.url} text={link.title} />
		</div>
	);
};

ItemArticle.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	link: PropTypes.object.isRequired,
};

export default ItemArticle;
