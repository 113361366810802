import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import imagesLoaded from 'imagesloaded';
import useViewport from '@assets/scripts/hooks/useViewport';
//components
import CardProject from '@components/molecules/cards/CardProject';
import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';

const ListProjects = ({
	modifiers,
	utility,
	className,
	projectsList,
	link,
	...otherProps
}) => {
	// vars
	const baseClass = 'list-projects';
	const rootClass = cx(baseClass, className, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	// --------------------------------
	// #region Render
	// --------------------------------

	return (
		<div
			className={rootClass}
			role="list"
			aria-label="Projects List"
			{...otherProps}
		>
			<div className="list-projects__list">
				{projectsList.map((project, index) => (
					<div
						className="list-projects__item"
						key={`project-${index}`}
						role="listitem"
					>
						<CardProject
							url={project.link}
							image={project.image}
							video={project.video}
							title={project.title}
							subtitle={project.subtitle}
							casestudy={project.casestudy}
							awarded={project.awarded}
							awardedTitle={project.awardedTitle}
							linkedProjects={project.linkedProjects}
						/>
					</div>
				))}
			</div>
			{link ? (
				<div className="list-projects__list">
					<div
						className="list-projects__item"
						style={{ justifyItems: 'flex-start' }}
					>
						<div
							style={{
								width: '100%',
								maxWidth: '488px',
								margin: 'auto',
								textAlign: 'center',
							}}
						>
							<ButtonPrimary
								href={link.url}
								text={link.title}
								data-target
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);

	// --------------------------------
	// #endregion
	// --------------------------------
};

ListProjects.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	projectsList: PropTypes.array.isRequired,
	link: PropTypes.object,
};

export default ListProjects;
