import '../button.scss';
import './styles.scss';

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from '@components/atoms/LinkPage';
import useHover from '@assets/scripts/hooks/useHover';
import useCombinedRefs from '@assets/scripts/hooks/useCombinedRefs';
import iconArrowBig from '@assets/icons/arrow-big.svg';

const ButtonService = React.forwardRef(
	({ className, href, text, ...otherProps }, forwardedRef) => {
		const rootClass = cx('btn', 'btn-service', className);
		// Tests if href string starts either with '/' or '#' -> use <Link> only for internal links!
		// @see https://www.gatsbyjs.org/docs/gatsby-link/#reminder-use-link-only-for-internal-links
		const internal = href.startsWith('/');

		// refs
		const innerRef = useRef(null);
		const refTarget = useCombinedRefs(forwardedRef, innerRef);

		// interaction
		const [hoverOn, hoverOff] = useHover();

		const buttonInner = (
			<>
				<span>
					<div data-boldproof-text={text}>{text}</div>
				</span>
				<span className="btn__icon">
					<svg>
						<use xlinkHref={`#${iconArrowBig.id}`} />
					</svg>
				</span>
			</>
		);

		if (internal) {
			return (
				<Link
					className={rootClass}
					to={href}
					{...otherProps}
					onMouseEnter={() => hoverOn()}
					onMouseLeave={() => hoverOff()}
					ref={refTarget}
					data-target
				>
					{buttonInner}
				</Link>
			);
		}
		return (
			<a
				className={rootClass}
				href={href}
				{...otherProps}
				onMouseEnter={() => hoverOn()}
				onMouseLeave={() => hoverOff()}
				ref={refTarget}
				data-target
			>
				{buttonInner}
			</a>
		);
	}
);

ButtonService.propTypes = {
	className: PropTypes.string,
	href: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};

ButtonService.displayName = 'ButtonService';

export default ButtonService;
