import './styles.scss';

import React, { useRef, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { gsap, CustomEase, SplitText } from '@assets/scripts/gsap/all';
import { PageContext } from '@assets/scripts/context/page-context';
import useViewport from '@assets/scripts/hooks/useViewport';
import imagesLoaded from 'imagesloaded';
import { getElementOffsetTop } from '@assets/scripts/helpers/getElementOffsetTop';
import { getElementOffsetLeft } from '@assets/scripts/helpers/getElementOffsetLeft';
import { getRandomNumber } from '@assets/scripts/helpers/getRandomNumber';

// components
import HeroTitle from '@components/atoms/HeroTitle';
import ButtonService from '@components/atoms/buttons/ButtonService';

// assets
import imgSun from '@assets/images/sun.svg';
import imgGoal from '@assets/images/goal.svg';
import imgStrategy from '@assets/images/ico-strategy.svg';
import imgBranding from '@assets/images/ico-branding.svg';
import imgSolutions from '@assets/images/solutions.svg';

// values
// icon position relative to title
// -> x: % of title's width
// -> y: % of icon's height
const X_ICON_SUN = -5;
const Y_ICON_SUN = -150;
const X_ICON_GOAL = 46;
const Y_ICON_GOAL = -40;
const X_ICON_STRATEGY = -20;
const Y_ICON_STRATEGY = -60;
const X_ICON_BRANDING = 30;
const Y_ICON_BRANDING = 30;
const X_ICON_SOLUTIONS = 100;
const Y_ICON_SOLUTIONS = 0;
const PROGRESS = 0.08;
const PROGRESS_DIFF = 0.02;
const PROGRESS_END = 0.999;

const Intro = ({
	modifiers,
	className,
	title,
	subtitle,
	servicesTitle,
	services,
	...otherProps
}) => {
	// refs
	const refEl = useRef(null);
	const refHeroInner = useRef(null);
	const refHeroBackground = useRef(null);
	const refHeroTitle = useRef();
	const refHeroTitleWrapper = useRef(null);
	const refIconsWrapper = useRef(null);
	const refIconSun = useRef(null);
	const refIconGoal = useRef(null);
	const refIconStrategy = useRef(null);
	const refIconBranding = useRef(null);
	const refIconSolutions = useRef(null);
	const refIconSunImage = useRef(null);
	const refIconGoalImage = useRef(null);
	const refIconStrategyImage = useRef(null);
	const refIconBrandingImage = useRef(null);
	const refIconSolutionsImage = useRef(null);
	const refServices = useRef(null);
	const refServicesTitle = useRef(null);
	const refServicesTitleSpan = useRef(null);
	const refServicesLinks = useRef([]);
	const refServicesIconStrategy = useRef(null);
	const refServicesIconBranding = useRef(null);
	const refServicesIconSolutions = useRef(null);
	const refServicesBottom = useRef(null);
	const refBiggestLine = useRef({
		offsetLeft: 0,
		offsetWidth: 0,
	});
	// vars
	const { fontsLoaded } = useContext(PageContext);
	const baseClass = 'intro';
	const rootClass = cx(baseClass, className, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});
	const isTabletBreakpoint = useRef(false);
	const isReady = useRef(false);
	const [isImagesLoaded, setIsImagesLoaded] = useState(false);
	const splitTitle = useRef();
	const scrollId = useRef();
	const tlLoad = useRef(gsap.timeline());
	const tlIntro = useRef(gsap.timeline());
	gsap.registerPlugin(CustomEase, SplitText);
	const triggerIntroAnimation = useRef(0);
	const currentAnimateScroll = useRef(0);
	const scrollAnimationOn = useRef(false);
	const finalAnimationOn = useRef(false);
	const progress = useRef({
		default: 1,
		slow: 1,
		extra: 1,
	});
	const startIconSun = useRef();
	const startIconGoal = useRef();
	const startIconStrategy = useRef();
	const startIconBranding = useRef();
	const startIconSolutions = useRef();
	const finalIconSun = useRef();
	const finalIconGoal = useRef();
	const finalIconStrategy = useRef();
	const finalIconBranding = useRef();
	const finalIconSolutions = useRef();
	const randomX = getRandomNumber(5, 20);
	const randomY = getRandomNumber(5, 20);
	const randomDelay = getRandomNumber(0, 1);
	const randomTime = getRandomNumber(4, 8);
	const randomAngle = getRandomNumber(5, 10);

	// --------------------------------
	// #region Hooks
	// --------------------------------

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		imagesLoaded(refEl.current, () => setIsImagesLoaded(() => true));

		return () => {
			cancelAnimationFrame(scrollId.current);
		};
	}, []);
	/* eslint-enable react-hooks/exhaustive-deps */

	/* eslint-disable react-hooks/exhaustive-deps */
	useEffect(() => {
		if (!fontsLoaded || !isImagesLoaded) return;
		// Set services position on component ready for SectionTitle to get correct top position
		if (isReady.current) return;
		isReady.current = true;
		setServicesPosition();
		updateIntro();
		loadAnimation();

		// dirty temporary fix to avoid icons scaling to 0 on scroll
		let ivCount = 0;
		const iv = setInterval(() => {
			ivCount++;
			if (ivCount > 10) clearInterval(iv);
			if (!isTabletBreakpoint.current) {
				setupScrollAnimation();
			}
		}, 200);

		return () => {
			if (iv) clearInterval(iv);
		};
		// end dirty temporary fix
	}, [isImagesLoaded, fontsLoaded]);
	/* eslint-enable react-hooks/exhaustive-deps */

	// when viewport size / scroll changes
	useViewport(
		({ viewportChanged, scrollChanged, isTablet }) => {
			if (viewportChanged) {
				isTabletBreakpoint.current = isTablet;
			}
			if (!isReady.current) return;
			if (viewportChanged) {
				updateIntro();
				setServicesPosition();
			}

			if (scrollChanged) {
				onPageScroll();
			}
		},
		{ scroll: true }
	);

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Functions
	// --------------------------------

	const updateIntro = () => {
		splitHeroTitle();
		updateHeroIconsPos();

		// scroll animation
		if (isTabletBreakpoint.current) {
			if (scrollId.current) {
				cancelAnimationFrame(scrollId.current);
				scrollId.current = null;
			}

			// reset styles from intro animation
			gsap.set('.line .word', { y: '0%' });
			gsap.set(refHeroBackground.current, { scale: '1' });
			gsap.set(refHeroTitleWrapper.current, { y: '' });
			gsap.set(refServices.current, { marginTop: '' });
			gsap.set(refServicesTitleSpan.current, { y: '' });
			gsap.set(refServicesLinks.current, { opacity: '', visibility: '' });
		} else {
			// setup styles for animation
			if (finalAnimationOn.current) {
				gsap.set('.line .word', { y: '-100%' });
				gsap.set(refHeroBackground.current, { scale: '0%' });
				gsap.set(refHeroTitleWrapper.current, { y: '-100%' });
				gsap.set(refServicesTitleSpan.current, { y: '0%' });
				gsap.set(refServicesLinks.current, {
					opacity: 1,
					visibility: 'visible',
				});
			} else {
				gsap.set('.line .word', { y: '0%' });
				gsap.set(refHeroBackground.current, { scale: '1' });
				gsap.set(refHeroTitleWrapper.current, { y: '0%' });
				gsap.set(refServicesTitleSpan.current, { y: '100%' });
				gsap.set(refServicesLinks.current, {
					opacity: 0,
					visibility: 'hidden',
				});
			}

			setupScrollAnimation();
			onPageScroll();
		}
	};

	// 📌 on page scroll
	const onPageScroll = () => {
		if (isTabletBreakpoint.current) {
			return;
		}

		// desktop
		scrollAnimationOn.current =
			window.pageYOffset < triggerIntroAnimation.current ? true : false;

		if (scrollId.current) {
			return;
		}
		scrollId.current = requestAnimationFrame(() => {
			handleIntroAnimation();
		});
	};

	// 📐 update icons position
	const updateHeroIconsPos = () => {
		// sun - top/left
		startIconSun.current = {
			x:
				refBiggestLine.current.offsetLeft +
				refBiggestLine.current.offsetWidth * (X_ICON_SUN / 100),
			y:
				refHeroTitleWrapper.current.offsetTop +
				refIconSun.current.getBoundingClientRect().height *
					(Y_ICON_SUN / 100),
			rotation: 0,
			scale: 1,
		};

		//goal - top/left
		startIconGoal.current = {
			x:
				refBiggestLine.current.offsetLeft +
				refBiggestLine.current.offsetWidth * (X_ICON_GOAL / 100),
			y:
				refHeroTitleWrapper.current.offsetTop +
				refIconGoal.current.getBoundingClientRect().height *
					(Y_ICON_GOAL / 100),
			rotation: -17,
			scale: 1,
		};

		// strategy - bottom/left
		startIconStrategy.current = {
			x:
				refBiggestLine.current.offsetLeft +
				refBiggestLine.current.offsetWidth * (X_ICON_STRATEGY / 100),
			y:
				refHeroTitleWrapper.current.offsetTop +
				refHeroTitleWrapper.current.offsetHeight +
				refIconStrategy.current.getBoundingClientRect().height *
					(Y_ICON_STRATEGY / 100),
			rotation: -21,
			scale: 1,
		};

		// branding - bottom/left
		startIconBranding.current = {
			x:
				refBiggestLine.current.offsetLeft +
				refBiggestLine.current.offsetWidth * (X_ICON_BRANDING / 100),
			y:
				refHeroTitleWrapper.current.offsetTop +
				refHeroTitleWrapper.current.offsetHeight +
				refIconBranding.current.getBoundingClientRect().height *
					(Y_ICON_BRANDING / 100),
			rotation: 26,
			scale: 1,
		};

		// solutions - top/left
		startIconSolutions.current = {
			x:
				refBiggestLine.current.offsetLeft +
				refBiggestLine.current.offsetWidth * (X_ICON_SOLUTIONS / 100),
			y:
				refHeroTitleWrapper.current.offsetTop +
				refIconSolutions.current.getBoundingClientRect().height *
					(Y_ICON_SOLUTIONS / 100),
			rotation: 11,
			scale: 1,
		};

		gsap.set(refIconSun.current, { ...startIconSun.current });
		gsap.set(refIconGoal.current, { ...startIconGoal.current });
		gsap.set(refIconStrategy.current, { ...startIconStrategy.current });
		gsap.set(refIconBranding.current, { ...startIconBranding.current });
		gsap.set(refIconSolutions.current, { ...startIconSolutions.current });
	};

	// ✂️ split intro title
	const splitHeroTitle = () => {
		refBiggestLine.current.offsetLeft = 0;
		refBiggestLine.current.offsetWidth = 0;

		// revert to its pre-split state
		if (splitTitle.current) {
			splitTitle.current.revert();
		}
		// split text
		splitTitle.current = new SplitText(
			refHeroTitle.current.titleOriginal(),
			{
				type: 'lines, words',
				linesClass: 'line line--++',
				wordsClass: 'word',
			}
		);

		//detect and save biggest line
		splitTitle.current.lines.forEach((line, index) => {
			let lineOffsetLeft = getElementOffsetLeft(line.firstChild);
			let lineOffsetWidth =
				getElementOffsetLeft(line.lastChild) +
				line.lastChild.offsetWidth -
				getElementOffsetLeft(line.firstChild);

			if (lineOffsetWidth > refBiggestLine.current.offsetWidth) {
				refBiggestLine.current.offsetLeft = lineOffsetLeft;
				refBiggestLine.current.offsetWidth = lineOffsetWidth;
			}
		});
	};

	// ✨ load animation
	const loadAnimation = () => {
		tlLoad.current
			.clear()
			.eventCallback('onComplete', applyFloating)
			.set(
				[
					refServicesTitle.current,
					refHeroTitleWrapper.current,
					refIconsWrapper.current,
				],
				{
					opacity: 1,
				},
				0
			)
			// icons
			.fromTo(
				refIconsWrapper.current,
				{
					y: '100px',
				},
				{
					duration: 0.3,
					ease: CustomEase.create('custom', '0.19, 0, 0.15, 1'),
					y: '0',
				},
				0
			)
			.to(
				[
					refIconSun.current,
					refIconGoal.current,
					refIconStrategy.current,
					refIconBranding.current,
					refIconSolutions.current,
				],
				{
					opacity: 1,
					duration: 0.6,
					ease: 'power4.out',
				},
				0
			)
			.fromTo(
				[refIconSunImage.current],
				{
					scale: 0,
				},
				{
					scale: 1,
					duration: 0.565,
					ease: CustomEase.create('custom', '0.19, 0, 0.15, 1'),
				},
				0.071
			)
			.fromTo(
				[refIconGoalImage.current],
				{
					scale: 0,
				},
				{
					scale: 1,
					duration: 0.565,
					ease: CustomEase.create('custom', '0.19, 0, 0.15, 1'),
				},
				0.043
			)
			.fromTo(
				[refIconStrategyImage.current],
				{
					scale: 0,
				},
				{
					scale: 1,
					duration: 0.565,
					ease: CustomEase.create('custom', '0.19, 0, 0.15, 1'),
				},
				0.015
			)
			.fromTo(
				[refIconBrandingImage.current],
				{
					scale: 0,
				},
				{
					scale: 1,
					duration: 0.565,
					ease: CustomEase.create('custom', '0.19, 0, 0.15, 1'),
				},
				0.185
			)
			.fromTo(
				[refIconSolutionsImage.current],
				{
					scale: 0,
				},
				{
					scale: 1,
					duration: 0.565,
					ease: CustomEase.create('custom', '0.19, 0, 0.15, 1'),
				},
				0
			)
			// intro background
			.to(
				refHeroBackground.current,
				{
					duration: 0.3,
					opacity: 1,
				},
				0
			);

		// slide up intro title + subtitle
		splitTitle.current.lines.forEach((line, index) => {
			const delay = index + 1 === 1 ? 0.042 : '<0.11';
			tlLoad.current.fromTo(
				`.line--${index + 1} .word`,
				{
					y: '100%',
				},
				{
					duration: 0.53,
					ease: CustomEase.create('custom', '0.19, 0, 0.15, 1'),
					y: '0%',
				},
				delay
			);
		});

		tlLoad.current.fromTo(
			refHeroTitle.current.subtitle(),
			{
				y: '100%',
			},
			{
				duration: 0.53,
				ease: CustomEase.create('custom', '0.19, 0, 0.15, 1'),
				y: '0%',
			},
			'<0.11'
		);
	};

	// ✨ floating animation

	const floatRotate = (target, direction) => {
		gsap.to(target, {
			duration: randomTime(),
			rotation: randomAngle(direction),
			delay: randomDelay(),
			ease: 'sine.inOut',
			onComplete: floatRotate,
			onCompleteParams: [target, direction * -1],
			overwrite: 'auto',
		});
	};

	const floatMoveX = (target, direction) => {
		gsap.to(target, {
			duration: randomTime(),
			x: randomX(direction),
			delay: randomDelay(),
			ease: 'sine.inOut',
			onComplete: floatMoveX,
			onCompleteParams: [target, direction * -1],
			overwrite: 'auto',
		});
	};

	const floatMoveY = (target, direction) => {
		gsap.to(target, {
			duration: randomTime(),
			y: randomY(direction),
			delay: randomDelay(),
			ease: 'sine.inOut',
			onComplete: floatMoveY,
			onCompleteParams: [target, direction * -1],
			overwrite: 'auto',
		});
	};

	const applyFloating = () => {
		if (/*isTabletBreakpoint.current || */ finalAnimationOn.current) return;
		// sun
		floatMoveX(refIconSunImage.current, 1);
		floatMoveY(refIconSunImage.current, -1);
		floatRotate(refIconSunImage.current, 1);
		// goal
		floatMoveX(refIconGoalImage.current, -1);
		floatMoveY(refIconGoalImage.current, 1);
		floatRotate(refIconGoalImage.current, -1);
		// strategy
		floatMoveX(refIconStrategyImage.current, -1);
		floatMoveY(refIconStrategyImage.current, -1);
		floatRotate(refIconStrategyImage.current, -1);
		// branding
		floatMoveX(refIconBrandingImage.current, -1);
		floatMoveY(refIconBrandingImage.current, 1);
		floatRotate(refIconBrandingImage.current, 1);
		// solutions
		floatMoveX(refIconSolutionsImage.current, 1);
		floatMoveY(refIconSolutionsImage.current, 1);
		floatRotate(refIconSolutionsImage.current, 1);
	};

	// Set services position
	const setServicesPosition = () => {
		if (!isTabletBreakpoint.current) {
			const servicesMarginTop =
				-window.innerHeight +
				refHeroInner.current.offsetTop +
				refHeroTitle.current.titleWrapper().offsetTop +
				refHeroTitle.current.titleWrapper().offsetHeight;
			gsap.set(refServices.current, {
				marginTop: `${servicesMarginTop}px`,
			});
		}
	};

	// 📐 setup scroll animation
	const setupScrollAnimation = () => {
		// sun
		finalIconSun.current = {
			x: startIconSun.current.x + 50,
			y: -refIconGoal.current.getBoundingClientRect().height - 150,
			rotation: -20,
			scale:
				refServicesIconStrategy.current.width /
				refIconSun.current.offsetWidth,
		};
		// goal
		finalIconGoal.current = {
			x: startIconGoal.current.x - 50,
			y: -refIconGoal.current.getBoundingClientRect().height - 150,
			rotation: 20,
			scale:
				refServicesIconStrategy.current.width /
				refIconGoal.current.offsetWidth,
		};

		// strategy
		finalIconStrategy.current = {
			x: refServicesIconStrategy.current.getBoundingClientRect().x,
			y:
				getElementOffsetTop(refServicesIconStrategy.current) -
				refHeroInner.current.offsetTop,
			rotation: 0,
			scale:
				refServicesIconStrategy.current.width /
				refIconStrategy.current.offsetWidth,
		};

		// branding
		finalIconBranding.current = {
			x: refServicesIconBranding.current.getBoundingClientRect().x,
			y:
				getElementOffsetTop(refServicesIconBranding.current) -
				refHeroInner.current.offsetTop,
			rotation: 0,
			scale:
				refServicesIconBranding.current.width /
				refIconBranding.current.offsetWidth,
		};

		// solutions
		finalIconSolutions.current = {
			x: refServicesIconSolutions.current.getBoundingClientRect().x,
			y:
				getElementOffsetTop(refServicesIconSolutions.current) -
				refHeroInner.current.offsetTop,
			rotation: 0,
			scale:
				refServicesIconSolutions.current.width /
				refIconSolutions.current.offsetWidth,
		};

		// services bottom
		triggerIntroAnimation.current =
			getElementOffsetTop(refServicesBottom.current) - window.innerHeight;
	};

	// ✨ scroll animation
	const handleIntroAnimation = () => {
		if (!scrollId.current) {
			return;
		}

		// animation with scroll
		if (scrollAnimationOn.current) {
			// first time it runs animation with scroll on reverse
			if (finalAnimationOn.current) {
				tlIntro.current
					.clear()
					.eventCallback('onComplete', applyFloating)
					// fade out services link
					.to(
						refServicesLinks.current,
						{
							opacity: 0,
							visibility: 'hidden',
							duration: 0.1,
							ease: 'power1.in',
						},
						0
					)
					// services title
					.fromTo(
						refServicesTitleSpan.current,
						{
							y: '0%',
						},
						{
							duration: 0.3,
							ease: CustomEase.create(
								'custom',
								'0.78, 0, 0.46, 1'
							),
							y: '100%',
						},
						0
					)
					// intro title
					.to(
						refHeroTitleWrapper.current,
						{
							duration: 0.4,
							ease: CustomEase.create(
								'custom',
								'0.29, 0, 0.23, 1'
							),
							y: '0%',
							overwrite: 'auto',
						},
						0.3
					)
					// intro background
					.to(
						refHeroBackground.current,
						{
							duration: 0.6,
							ease: CustomEase.create(
								'custom',
								'0.29, 0, 0.23, 1'
							),
							scale: 1,
						},
						0
					)
					// intro title shadow
					.to(
						refHeroTitle.current.titleClone(),
						{
							duration: 0.2,
							opacity: 1,
						},
						0.9
					)
					.to(
						refHeroTitle.current.subtitle(),
						{
							duration: 0.6,
							ease: CustomEase.create(
								'custom',
								'0.19, 0, 0.15, 1'
							),
							y: '0%',
							overwrite: true,
						},
						0.3
					);

				splitTitle.current.lines.reverse().forEach((line, index) => {
					const delay = '<0.08';
					const notReversedIndex =
						splitTitle.current.lines.length - index;

					tlIntro.current.to(
						`.line--${notReversedIndex} .word`,
						{
							duration: 0.6,
							ease: CustomEase.create(
								'custom',
								'0.19, 0, 0.15, 1'
							),
							y: '0%',
							overwrite: true,
						},
						delay
					);
				});

				finalAnimationOn.current = false;
			}

			const finalScroll = window.pageYOffset * 0.3;

			currentAnimateScroll.current = gsap.utils.interpolate(
				currentAnimateScroll.current,
				finalScroll,
				0.1
			);

			if (Math.abs(currentAnimateScroll.current - finalScroll) < 0.5) {
				cancelAnimationFrame(scrollId.current);
				scrollId.current = null;
				currentAnimateScroll.current = finalScroll;
			}

			progress.current = {
				default:
					currentAnimateScroll.current /
					triggerIntroAnimation.current,
				slow:
					currentAnimateScroll.current /
					triggerIntroAnimation.current,
				extra:
					currentAnimateScroll.current /
					triggerIntroAnimation.current,
			};
		}
		// animation with ease
		else {
			// first time it runs animation with ease
			if (!finalAnimationOn.current) {
				// kill all floating tweens
				gsap.killTweensOf(refIconSunImage.current);
				gsap.killTweensOf(refIconGoalImage.current);
				gsap.killTweensOf(refIconStrategyImage.current);
				gsap.killTweensOf(refIconBrandingImage.current);
				gsap.killTweensOf(refIconSolutionsImage.current);

				tlIntro.current
					.clear()
					// intro title shadow
					.to(
						refHeroTitle.current.titleClone(),
						{
							duration: 0.2,
							opacity: 0,
						},
						0
					)
					// intro background
					.to(
						refHeroBackground.current,
						{
							duration: 0.6,
							scale: 0,
							ease: CustomEase.create(
								'custom',
								'0.29, 0, 0.23, 1'
							),
						},
						0
					)
					// intro title
					.to(
						refHeroTitleWrapper.current,
						{
							duration: 0.5,
							ease: CustomEase.create(
								'custom',
								'0.29, 0, 0.23, 1'
							),
							y: '-30%',
							overwrite: 'auto',
						},
						0
					)
					// services title
					.fromTo(
						refServicesTitleSpan.current,
						{
							y: '100%',
						},
						{
							duration: 0.3,
							ease: CustomEase.create(
								'custom',
								'0.19, 0, 0.15, 1'
							),
							y: '0%',
						},
						0.2
					)
					// image icons
					.to(
						[
							refIconSunImage.current,
							refIconGoalImage.current,
							refIconStrategyImage.current,
							refIconBrandingImage.current,
							refIconSolutionsImage.current,
						],
						{
							duration: 0.3,
							x: 0,
							y: 0,
							rotation: 0,
							ease: 'sine.inOut',
						},
						0
					)
					// fade in services link
					.to(
						refServicesLinks.current,
						{
							opacity: 1,
							visibility: 'visible',
							duration: 0.4,
							stagger: 0.2,
							ease: 'power1.in',
						},
						0.25
					);

				splitTitle.current.lines.forEach((line, index) => {
					const delay = 0.04 * index;

					tlIntro.current.to(
						`.line--${index + 1} .word`,
						{
							duration: 0.6,
							ease: 'power4.out',
							y: '-100%',
							overwrite: true,
						},
						delay
					);
				});

				tlIntro.current.to(
					refHeroTitle.current.subtitle(),
					{
						duration: 0.6,
						ease: 'power4.out',
						y: '-100%',
						overwrite: true,
					},
					0.04 * (splitTitle.current.lines.length + 1)
				);

				finalAnimationOn.current = true;
			}

			currentAnimateScroll.current = triggerIntroAnimation.current;

			const progressDefault = gsap.utils.interpolate(
				progress.current.default,
				1,
				PROGRESS
			);
			const progressSlow = gsap.utils.interpolate(
				progress.current.slow,
				1,
				PROGRESS - PROGRESS_DIFF
			);
			const progressExtra = gsap.utils.interpolate(
				progress.current.extra,
				1,
				PROGRESS - PROGRESS_DIFF * 2
			);
			progress.current = {
				default: progressDefault,
				slow: progressSlow,
				extra: progressExtra,
			};

			if (
				progress.current.default > PROGRESS_END &&
				progress.current.slow > PROGRESS_END &&
				progress.current.extra > PROGRESS_END
			) {
				cancelAnimationFrame(scrollId.current);
				scrollId.current = null;
				progress.current = {
					default: 1,
					slow: 1,
					extra: 1,
				};
			}
		}

		// sun
		gsap.set(refIconSun.current, {
			x: gsap.utils.interpolate(
				startIconSun.current.x,
				finalIconSun.current.x,
				progress.current.default
			),
			y: gsap.utils.interpolate(
				startIconSun.current.y,
				finalIconSun.current.y,
				progress.current.default
			),
			rotation: gsap.utils.interpolate(
				startIconSun.current.rotation,
				finalIconSun.current.rotation,
				progress.current.default
			),
			scale: gsap.utils.interpolate(
				startIconSun.current.scale,
				finalIconSun.current.scale,
				progress.current.default
			),
		});

		// goal
		gsap.set(refIconGoal.current, {
			x: gsap.utils.interpolate(
				startIconGoal.current.x,
				finalIconGoal.current.x,
				progress.current.slow
			),
			y: gsap.utils.interpolate(
				startIconGoal.current.y,
				finalIconGoal.current.y,
				progress.current.slow
			),
			rotation: gsap.utils.interpolate(
				startIconGoal.current.rotation,
				finalIconGoal.current.rotation,
				progress.current.slow
			),
			scale: gsap.utils.interpolate(
				startIconGoal.current.scale,
				finalIconGoal.current.scale,
				progress.current.slow
			),
		});

		// strategy
		gsap.set(refIconStrategy.current, {
			x: gsap.utils.interpolate(
				startIconStrategy.current.x,
				finalIconStrategy.current.x,
				progress.current.default
			),
			y: gsap.utils.interpolate(
				startIconStrategy.current.y,
				finalIconStrategy.current.y,
				progress.current.default
			),
			rotation: gsap.utils.interpolate(
				startIconStrategy.current.rotation,
				finalIconStrategy.current.rotation,
				progress.current.default
			),
			scale: gsap.utils.interpolate(
				startIconStrategy.current.scale,
				finalIconStrategy.current.scale,
				progress.current.default
			),
		});

		// branding
		gsap.set(refIconBranding.current, {
			x: gsap.utils.interpolate(
				startIconBranding.current.x,
				finalIconBranding.current.x,
				progress.current.slow
			),
			y: gsap.utils.interpolate(
				startIconBranding.current.y,
				finalIconBranding.current.y,
				progress.current.slow
			),
			rotation: gsap.utils.interpolate(
				startIconBranding.current.rotation,
				finalIconBranding.current.rotation,
				progress.current.slow
			),
			scale: gsap.utils.interpolate(
				startIconBranding.current.scale,
				finalIconBranding.current.scale,
				progress.current.slow
			),
		});

		// solutions
		gsap.set(refIconSolutions.current, {
			x: gsap.utils.interpolate(
				startIconSolutions.current.x,
				finalIconSolutions.current.x,
				progress.current.extra
			),
			y: gsap.utils.interpolate(
				startIconSolutions.current.y,
				finalIconSolutions.current.y,
				progress.current.extra
			),
			rotation: gsap.utils.interpolate(
				startIconSolutions.current.rotation,
				finalIconSolutions.current.rotation,
				progress.current.extra
			),
			scale: gsap.utils.interpolate(
				startIconSolutions.current.scale,
				finalIconSolutions.current.scale,
				progress.current.extra
			),
		});

		if (scrollId.current) {
			scrollId.current = requestAnimationFrame(() => {
				handleIntroAnimation();
			});
		}
	};

	// --------------------------------
	// #endregion
	// --------------------------------

	// --------------------------------
	// #region Render
	// --------------------------------

	return (
		<section
			className={rootClass}
			aria-label="Intro"
			data-intro
			{...otherProps}
			ref={refEl}
		>
			<div className="intro__hero">
				<div ref={refHeroInner} className="intro__hero__inner">
					<div
						className="intro__hero__icons"
						data-intro
						data-animation-page
						ref={refIconsWrapper}
					>
						<div
							className="intro__hero__icon"
							id="intro-hero-icon-sun"
							ref={refIconSun}
						>
							<img
								ref={refIconSunImage}
								className="intro__hero__icon-image"
								id="intro-hero-icon-sun-image"
								src={imgSun}
								alt=""
								loading="lazy"
							/>
						</div>

						<div
							className="intro__hero__icon"
							id="intro-hero-icon-goal"
							ref={refIconGoal}
						>
							<img
								ref={refIconGoalImage}
								className="intro__hero__icon-image"
								id="intro-hero-icon-goal-image"
								src={imgGoal}
								alt=""
								loading="lazy"
							/>
						</div>

						<div
							className="intro__hero__icon"
							id="intro-hero-icon-strategy"
							ref={refIconStrategy}
						>
							<img
								ref={refIconStrategyImage}
								className="intro__hero__icon-image"
								id="intro-hero-icon-strategy-image"
								src={imgStrategy}
								alt=""
								loading="lazy"
							/>
						</div>

						<div
							className="intro__hero__icon"
							id="intro-hero-icon-branding"
							ref={refIconBranding}
						>
							<img
								ref={refIconBrandingImage}
								className="intro__hero__icon-image"
								id="intro-hero-icon-branding-image"
								src={imgBranding}
								alt=""
								loading="lazy"
							/>
						</div>

						<div
							className="intro__hero__icon"
							id="intro-hero-icon-solutions"
							ref={refIconSolutions}
						>
							<img
								ref={refIconSolutionsImage}
								className="intro__hero__icon-image"
								id="intro-hero-icon-solutions-image"
								src={imgSolutions}
								alt=""
								loading="lazy"
							/>
						</div>
					</div>
					<div
						className="intro__hero__title grid"
						ref={refHeroTitleWrapper}
					>
						<HeroTitle
							data-intro
							data-animation-page="200"
							ref={refHeroTitle}
							title={title}
							subtitle={subtitle}
						/>
					</div>
					<span
						ref={refHeroBackground}
						className="intro__hero__background"
						data-animation-page
					></span>
				</div>
			</div>
			<div
				ref={refServices}
				className="intro__services grid"
				role="list"
				aria-label="Services"
				data-animation-page
			>
				<h2 ref={refServicesTitle} className="intro__services__title">
					<span ref={refServicesTitleSpan}>{servicesTitle}</span>
				</h2>
				<ul className="intro__services__list">
					<li data-target>
						<div className="service-item">
							<img
								ref={refServicesIconStrategy}
								className="service-item__icon"
								src={imgStrategy}
								alt={services[0].alt}
								loading="lazy"
							/>
							<ButtonService
								ref={(el) => {
									refServicesLinks.current[0] = el;
								}}
								text={services[0].text}
								href={services[0].link}
							/>
						</div>
					</li>

					<li data-animation-page data-target>
						<div className="service-item">
							<img
								ref={refServicesIconBranding}
								className="service-item__icon"
								src={imgBranding}
								alt={services[1].alt}
								loading="lazy"
							/>
							<ButtonService
								ref={(el) => {
									refServicesLinks.current[1] = el;
								}}
								text={services[1].text}
								href={services[1].link}
							/>
						</div>
					</li>

					<li data-animation-page data-target>
						<div className="service-item">
							<img
								ref={refServicesIconSolutions}
								className="service-item__icon"
								src={imgSolutions}
								alt={services[2].alt}
								loading="lazy"
							/>
							<ButtonService
								ref={(el) => {
									refServicesLinks.current[2] = el;
								}}
								text={services[2].text}
								href={services[2].link}
							/>
						</div>
					</li>
				</ul>
				<div
					ref={refServicesBottom}
					className="intro__services__bottom"
					aria-hidden="true"
				></div>
			</div>
		</section>
	);

	// --------------------------------
	// #endregion
	// --------------------------------
};

Intro.defaultProps = {
	title:
		'Transform your business into an interactive brand that people love online and beyond',
	servicesTitle: 'We amplify',
	services: ['Strategy', 'Branding', 'Web Solutions'],
};

Intro.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	servicesTitle: PropTypes.string,
	services: PropTypes.array,
};

export default Intro;
