import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import CardTeam from '@components/molecules/cards/CardTeam';

const ListTeam = ({
	modifiers,
	utility,
	className,
	teamMembers,
	...otherProps
}) => {
	const baseClass = 'list-team';

	const rootClass = cx(baseClass, className, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	return (
		<div
			className={rootClass}
			role="list"
			aria-label="Team List"
			{...otherProps}
		>
			{teamMembers.map((member, index) => (
				<CardTeam
					key={`card-team-${index}`}
					name={member.name}
					teamRole={member.role}
					image={member.image || null}
					link={member.link}
					modifiers={member.modifiers}
					statistic={member.statistic}
					role="listitem"
				/>
			))}
		</div>
	);
};

ListTeam.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	teamMembers: PropTypes.array.isRequired,
};

export default ListTeam;
