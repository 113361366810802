import '../card.scss';
import './styles.scss';

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
// assets
import useHover from '@assets/scripts/hooks/useHover';
import { PageContext } from '@assets/scripts/context/page-context';
import iconAward from '@assets/icons/award.svg';
// components
import LinkPage from '@components/atoms/LinkPage';
import Link from '@components/atoms/Link';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Image from '@components/atoms/Image';

// link (internal/external)
const CardLink = ({ children, url, target, rel, ...props }) =>
	url[0] === '/' ? (
		<LinkPage to={url} {...props}>
			{children}
		</LinkPage>
	) : (
		<Link href={url} {...{ ...props, target, rel }}>
			{children}
		</Link>
	);

const CardProject = ({
	modifiers,
	className,
	url,
	image,
	title,
	subtitle,
	awarded,
	awardedTitle,
	casestudy,
	linkedProjects,
	...otherProps
}) => {
	const baseClass = 'card-project';
	const defaultClass = 'card';
	const rootClass = cx(baseClass, className, defaultClass, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});
	const aspectRatio =
		image?.aspectRatio ?? image?.fluid?.aspectRatio ?? undefined;

	// interaction
	const [hoverOn, hoverOff] = useHover({ dotScale: 0.8 });

	const { currentLanguage } = useContext(PageContext);

	return (
		<>
			<article
				className={rootClass}
				data-card
				{...otherProps}
				style={{
					maxWidth:
						aspectRatio < 1 ? aspectRatio * 488 + 'px' : '488px',
				}}
			>
				<div className="card__inner">
					<div className="card__click" data-target>
						<figure className="card__thumbnail" aria-label={title}>
							<Image
								{...image}
								objectFit="contain"
								className="card__img"
							/>
							<span className="card__shadow"></span>
						</figure>
						<div className="card__info">
							<CardLink
								className="card__link"
								url={url}
								target="_blank"
								rel="noopener"
								onMouseEnter={() => hoverOn()}
								onMouseLeave={() => hoverOff()}
							>
								<h3
									className="card__title"
									data-boldproof-text={title}
									dangerouslySetInnerHTML={{ __html: title }}
								/>
							</CardLink>
							<div className="card__details">
								<p className="card__subtitle">{subtitle}</p>
								{awarded && (
									<svg
										className="icon icon--award"
										role="img"
										aria-label="Award"
									>
										<title>{awardedTitle}</title>
										<use xlinkHref={`#${iconAward.id}`} />
									</svg>
								)}
							</div>
						</div>
					</div>
					{casestudy && (
						<div className="card__links">
							<ButtonSecondary href={url} text="Portfolio" />
							<ButtonSecondary
								href={casestudy}
								text="Casestudy"
							/>
						</div>
					)}
					{linkedProjects ? (
						<div className="card-project__linkedProjects">
							<p className="card-project__linkedProjects__title uptitle">
								<span>
									{currentLanguage === 'fr'
										? 'En lien'
										: 'Related'}
								</span>
							</p>
							{linkedProjects.map((prj) => (
								<ButtonSecondary
									key={prj.link}
									href={prj.link}
									text={prj.title}
									// data-target
								/>
							))}
						</div>
					) : null}
				</div>
			</article>
		</>
	);
};

CardProject.defaultProps = {
	awarded: false,
	awardedTitle: '',
};

CardProject.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	image: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string,
	awarded: PropTypes.bool,
	awardedTitle: PropTypes.string,
	casestudy: PropTypes.string,
};

export default CardProject;
