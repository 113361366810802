/**
 * Get random number
 * @param {Number} min
 * @param {Number} max
 */

export const getRandomNumber = (min, max) => {
	const delta = max - min;
	return (direction = 1) => {
		const randomNumber = (min + delta * Math.random()) * direction;
		return randomNumber;
	};
};
